import { camelCase, has, upperFirst } from 'lodash-es';
import { isVideo } from '../../media';

import type {
  Article,
  Asset,
  Blog,
  Collection,
  Product,
  ProductVariant,
} from '@tectonic/types';
import type { MetaFunctionUrls } from './meta.types';

const baseUrl = 'https://vaaree.com';

const getSearchPageUrl = (urls: MetaFunctionUrls) => {
  const { origin, hostname } = new URL(urls.requestUrl);
  return `${baseUrl}/search`;
};

const getBlogUrl = (blog: Blog, urls: MetaFunctionUrls) => {
  const { hostname } = new URL(urls.requestUrl);
  return `${baseUrl}/blogs/${blog.slug}`;
};

const getArticleUrl = (article: Article, urls: MetaFunctionUrls) => {
  const { hostname } = new URL(urls.requestUrl);
  return `${baseUrl}/blogs/${article.blogDetails?.slug}/${article.slug}`;
};

const getPdpUrl = (product: Product, urls: MetaFunctionUrls) => {
  const { hostname } = new URL(urls.requestUrl);
  return `${baseUrl}/products/${product.slug}`;
};

const getCollectionUrl = (
  collection: Collection,
  urls: MetaFunctionUrls,
  queryParams: URLSearchParams
) => {
  const { hostname } = new URL(urls.requestUrl);
  if (queryParams.has('page')) {
    return `https://${hostname}/collections/${
      collection.slug
    }?page=${queryParams.get('page')}`;
  }
  return `${baseUrl}/collections/${collection.slug}`;
};

const toProductAttributes = (variant: ProductVariant) => {
  const attributes = [
    {
      key: variant.basisAttr1Name,
      value: variant.basisAttr1Value,
    },
    {
      key: variant.basisAttr2Name,
      value: variant.basisAttr2Value,
    },
    {
      key: variant.basisAttr3Name,
      value: variant.basisAttr3Value,
    },
  ];

  const result: Record<string, string> = {};
  for (const attr of attributes) {
    if (attr.key && attr.value) {
      result[attr.key.toLowerCase()] = attr.value;
    }
  }

  return result;
};

const toProductAvailability = (variant: ProductVariant) => {
  const status = upperFirst(camelCase(variant.stockStatus));
  return `https://schema.org/${status}`;
};

const toAggregateRating = (product: Product) => {
  const { average = 0, count = 0 } = product.ratingStats ?? {};
  if (average === 0 || count === 0) {
    return null;
  }

  return {
    '@type': 'AggregateRating',
    ratingValue: average,
    ratingCount: count,
    reviewCount: product.ratingStats?.reviewCount ?? 1,
    bestRating: product.ratingStats?.maxScore ?? 5,
    worstRating: product.ratingStats?.minScore ?? 1,
  };
};

const getImageSource = (asset: Asset): string => {
  if (isVideo(asset.mimeType)) {
    return asset.originalUrl;
  }
  return asset.cdnUrl ?? asset.originalUrl;
};

const toAssetUrl = (asset: Asset, damUrl: string) => getImageSource(asset);

const toAssetUrls = (assets: Asset[], damUrl: string) =>
  assets.map((asset) => toAssetUrl(asset, damUrl));

const parentMetaProps = new Set<string>([
  'description',
  'keywords',
  'author',
  'og:site_name',
  'og:url',
  'og:title',
  'og:type',
  'og:description',
  'og:image',
  'og:image:secure_url',
  'og:image:width',
  'og:image:height',
]);

const removeParentBreadcrumb = (parentMeta: any[]) =>
  parentMeta.filter((tag: any) => {
    if (
      has(tag, 'script:ld+json.@type') &&
      tag['script:ld+json']['@type'] === 'BreadcrumbList'
    ) {
      return false;
    }
    return true;
  });

const sanitizeParentMeta = (matches: any[]) =>
  // https://remix.run/docs/en/main/route/meta#merging-with-parent-meta
  matches
    .flatMap((match) => match.meta ?? [])
    .filter((tag: any) => {
      if (
        has(tag, 'title') ||
        parentMetaProps.has(tag.name) ||
        parentMetaProps.has(tag.property)
      ) {
        return false;
      }
      return true;
    });

export {
  getArticleUrl,
  getBlogUrl,
  getCollectionUrl,
  getPdpUrl,
  getSearchPageUrl,
  removeParentBreadcrumb,
  sanitizeParentMeta,
  toAggregateRating,
  toAssetUrl,
  toAssetUrls,
  toProductAttributes,
  toProductAvailability,
};
