import { Logger } from "@tectonic/logger";
import { LocalStorage } from "@tectonic/storage";
import { isNil } from "lodash-es";
import { withTimeout } from "../analytics/utils";

const getCerberusData = async () => {
  try {
    let cerberusData = LocalStorage.getItem('tt_cerberus_data') || '{}';
    if (!isNil(cerberusData)) {
      cerberusData = JSON.parse(cerberusData);
    }
    return cerberusData;
  } catch (err) {
    // log to sentry
  }
  return {};
};

const getCerberusInfo = async () => {
  let cerberusInfo = {}
  try {
    cerberusInfo = await withTimeout<{}>(
      getCerberusData(),
      {
        message: 'getCerberusInfo timeout',
        duration: 500
      }
    );
  } catch (error) {
    Logger.error(`[getCerberusInfoErr]`, error);
  }

  return cerberusInfo ?? {};
};

export { getCerberusInfo };
