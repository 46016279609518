import { useStyleConfig } from '@tectonic/elemason-components';
import clsx from 'clsx';
import { Facet } from './Facet';

import type { FC } from 'react';
import type { FacetSectionProps } from './FacetSection.types';

const FacetSection: FC<FacetSectionProps> = ({
  className,
  config,
  facets,
  onFacetToggle,
  activeFacet,
}) => {
  const { facetSection } = config ?? {};

  const listStyleConfig = useStyleConfig(facetSection?.container ?? {});

  return (
    <ul
      style={listStyleConfig.style}
      className={clsx(className, listStyleConfig.className)}
    >
      {facets
        .filter(
          (facet) => !facet?.hideInFilterPanel
        )
        .map((facet) => {
          const isActive = facet.name === activeFacet;
          return (
            <li key={facet.name}>
              <Facet
                isActive={isActive}
                config={config?.facet}
                facet={facet}
                onClick={onFacetToggle}
              />
            </li>
          );
        })}
    </ul>
  );
};

FacetSection.displayName = 'ElemasonSearchFacetSection';

export { FacetSection };
