import { ElemasonFragmentProvider } from '../../contexts';
import { Cell } from '../../core';
import { useHeadlessCarousel } from './hooks';

import type { FC } from 'react';
import { useFragmentValue, usePageFragment } from '../../hooks';
import type { HeadlessCarouselProps } from './HeadlessCarousel.types';

const HeadlessCarousel: FC<HeadlessCarouselProps> = ({ widget }) => {
  const {
    mainCarouselRef,
    thumbCarouselRef,
    hasThumbnails,
    onThumbClick,
    list,
    styleConfig,
    mainSlideFragment,
    mainSlideFragmentValue,
    thumbSlideFragment,
    thumbSlideFragmentValue,
    selectedSlideIndex,
    onScrollNext,
    onScrollPrev,
  } = useHeadlessCarousel(widget);
  const data = widget.data;
  const wConfig = widget.config!;
  const fragment = usePageFragment(data?.fragment);
  const fragmentValue = useFragmentValue(fragment);
  const fragmentData = fragmentValue({
    onScrollNext,
    onScrollPrev,
    selectedSlideIndex,
  });
  return (
    <>
      <div {...styleConfig.main.viewport} ref={mainCarouselRef}>
        <div {...styleConfig.main.container}>
          {list?.map((item, index) => (
            <div {...styleConfig.main.slide} key={index}>
              <ElemasonFragmentProvider
                value={mainSlideFragmentValue({
                  selectedSlideIndex,
                  slide: item,
                  slideIndex: index,
                })}
              >
                {mainSlideFragment?.cells.map((cell) => (
                  <Cell key={cell.id} cell={cell} />
                ))}
              </ElemasonFragmentProvider>
            </div>
          ))}
        </div>
      </div>
      {hasThumbnails && (
        <div {...styleConfig.thumb.viewport} ref={thumbCarouselRef}>
          <div {...styleConfig.thumb.container}>
            {list?.map((item, index) => (
              /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions  */
              <div
                {...styleConfig.thumb.slide}
                key={index}
                onClick={() => onThumbClick(index)}
              >
                <ElemasonFragmentProvider
                  value={thumbSlideFragmentValue({
                    selectedSlideIndex,
                    slide: item,
                    slideIndex: index,
                  })}
                >
                  {thumbSlideFragment?.cells.map((cell) => (
                    <Cell key={cell.id} cell={cell} />
                  ))}
                </ElemasonFragmentProvider>
              </div>
            ))}
          </div>
        </div>
      )}
      <ElemasonFragmentProvider value={fragmentData}>
        {fragment?.cells.map((cell) => <Cell key={cell.id} cell={cell} />)}
      </ElemasonFragmentProvider>
    </>
  );
};

HeadlessCarousel.displayName = 'HeadlessCarousel';

export { HeadlessCarousel };
